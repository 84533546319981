<template>
    <div>
        <v-toolbar style="background:#cad9e5;">
            <v-row>
                <v-btn
                    tile
                    color=""
                    style="margin-right:1%;"
                    @click="getDatas"
                    v-bind:disabled="this.cancelDisabled==false"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    검색
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;display:none;"
                    @click="dialog_wearing=true"       
                    v-bind:disabled="this.cancelDisabled==false"
                    @mousedown="resetAddForm"
                >
                    <v-icon left>
                        mdi-plus-circle
                    </v-icon>
                    입고
                </v-btn>
                <v-btn
                    tile
                    color="error"
                    style="margin-right:1%;display:none;"
                    
                    @click="dialogCancel=true"
                    v-bind:disabled="this.cancelDisabled==true"
                >
                    <v-icon left>
                        mdi-minus-circle
                    </v-icon>
                    입고취소
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    tile
                    color="success"
                    style="margin-right:1%;display:none;"
                    @click="wearingExcelUpload"
                >
                    <v-icon left>
                        mdi-upload
                    </v-icon>
                    업로드
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                    @click="wearingExcelDownload"
                >
                    <v-icon left>
                        mdi-download
                    </v-icon>
                    입고내역
                </v-btn>
                <v-btn
                    tile
                    color="error"
                    style="margin-right:1%;display:none;"
                    @click="wearingExcelTemplate"
                >
                    <v-icon left>
                        mdi-file-excel
                    </v-icon>
                    템플릿
                </v-btn>
            </v-row>
        </v-toolbar>


        <v-toolbar style="background:#cad9e5;">
            <v-toolbar-items style="margin-right:1%; margin-top:3%;">
                <v-text-field
                    v-model="lot_code"
                    id="wearing-item_code"
                    label="LOT번호"
                    clearable
                    v-on:lot_code_update="onTextChangne2('lotno', $event)"
                    @change="value => onTextChangne('lotno', value)"
                    @input="onTextChangne2('lotno', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                    ref="lotno_val"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items style="margin-right:1%; margin-top:3%;">
                <v-text-field
                    v-model="i_code"
                    id="wearing-item_code"
                    label="품번"
                    clearable
                    v-on:i_code_update="onTextChangne2('item_code', $event)"
                    @change="value => onTextChangne('item_code', value)"
                    @input="onTextChangne2('item_code', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                    ref="itemcode_val"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items style="margin-right:1%; margin-top:3%;">
                <v-text-field
                    id="wearing-item_name"
                    label="품명"
                    clearable
                    @change="value => onTextChangne('item_name', value)"
                    @input="onTextChangne2('item_name', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items style="margin-right:1%; margin-top:3%;display:none;">
                <v-text-field
                    id="wearing-bin_code"
                    label="위치정보"
                    clearable
                    @change="value => onTextChangne('bin_code', value)"
                    @input="onTextChangne2('bin_code', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items style="margin-right:1%; margin-top:3%;">
                <v-menu
                    v-model="wearing_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date_range"
                            label="입/출고 날짜"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :append-outer-icon="'mdi-refresh'"
                            @click:append-outer="refreshDateRange"
                            @mousedown="disabledSelectAll"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="start_date"
                        @input="input_start"
                        locale="ko-KR"
                    ></v-date-picker>
                    <v-date-picker
                        v-model="end_date"
                        @input="input_end"
                        locale="ko-KR"
                    ></v-date-picker>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <div style="width:100%;overflow-x:auto;">
            <div style="width:100%;">
        <v-data-table 
            v-model="selected"
            :headers="headers"
            :items="datas"
            class="elevation-1"
            show-select
            item-key="id"
            @click:col[0]="onClickCol"
            @click:row="onClickRow"
            height="100%"
            fixed-header
            dense

            :items-per-page="perPage"
            :footer-props="footerProps"
            :page.sync="page"
            :server-items-length="dataCounts"
            :options.sync="options"
            ref="dataTable"
            style="background-color: #f0f0f0;" 
        >   
                </v-data-table>
    </div>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="dialog_wearing"
            width='80%'
            height="45%"
            max-width="1200px"
            style="height: 45%;"
            scrollable      
            @change="load_container"                                                       
            >
            <div style="width:100%; height:100%; display:flex; background:white;">
                <div id="form" style="width:70%; height:650px;">
                    <v-card>
                        <v-card-title>
                            <span class="text-h4">입고</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-autocomplete
                                            id="itemsid"
                                            v-model="search_item"
                                            auto-selct-first
                                            
                                            ref="autoinput"  
                                            label="검색"
                                            clear-icon="mdi-close-circle"
                                            :append-icon="'mdi-magnify'"
                                            type="text"
                                            :items="searchItemList"
                                            :search-input.sync="searchAdd"
                                            hide-details
                                            item-text="search_name"
                                            item-value="search_name"
                                            return-object
                                            @mousedown="searchAdd_void"
                                            @change="clickAddItemCode" 
                                            @click="searchAdd_void, $refs.add_lotno.focus()"
                                            v-on:keyup.enter="searchAdd_void,$refs.add_lotno.focus()"
                                            @keydown.enter="closeList"
                                        ></v-autocomplete>     
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="add_lotno"
                                            v-model="lotno"
                                            label="LOT번호"
                                            v-on:keyup.enter="$refs.quan.focus()"
                                            autofocus
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="blotid"
                                            v-model="blotid"
                                            label="BATCH LOT-ID"                                         
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="slotno"
                                            v-model="slotno"
                                            label="공급사 LOT번호"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="scomp_code"
                                            v-model="scomp_code"
                                            label="공급사 코드"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="scomp_name"
                                            v-model="scomp_name"
                                            label="공급 업체명"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="validity_date"
                                            v-model="validity_date"
                                            label="유효기간"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="add_item_code"
                                            v-model="add_item_code"
                                            label="품번"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="add_item_name"
                                            v-model="add_item_name"
                                            label="품명"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="add_specification"
                                            v-model="add_specification"
                                            label="규격"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="add_detail_info"
                                            v-model="add_detail_info"
                                            label="상세사양"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            v-model="add_quan"
                                            label="수량"
                                            required
                                            ref="quan"
                                            v-on:keyup.enter="$refs.btn_add_bin_code.focus()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-autocomplete
                                            ref="btn_add_bin_code"
                                            v-model="add_bin_code"
                                            label="위치정보"
                                            clear-icon="mdi-close-circle"
                                            :append-icon="'mdi-magnify'"
                                            type="text"
                                            :items="addBinCodes"
                                            :search-input.sync="searchBinAdd"
                                            hide-details
                                            item-text="bin_code"
                                            item-value="bin_code"
                                            return-object
                                            @change="clickAddBinCode"
                                            @click="searchBinAdd_void"
                                            
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="6" align="center">
                                        <v-btn
                                            tile
                                            color="primary"
                                            @click="execUnitWearing"
                                            ref="instockbtn"
                                        >
                                            입고
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" align="center">
                                        <v-btn
                                            tile
                                            color="error"
                                            @click="() => dialog_wearing = false"
                                    >   
                                            취소
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <div id="cards" style="width:30%; height:650px; overflow: scroll;">
                    <v-card 
                        v-for="(card, index) in cards" 
                        :key="index" 
                        style="margin-bottom:1%; background:#c6c6f8;"
                        @click="clickCardBin(card)"    
                    >
                        <v-card-title>{{ card.bin_code }}  - {{ card.lotno }}</v-card-title>
                        <v-card-text>{{ card.quan + ' ' + card.unit_code }}</v-card-text>
                    </v-card>
                </div>
            </div>
        </v-dialog>

        <v-dialog
            v-model="dialogCancel"
            width="500px"
            max-width="500px"
        >
            <v-card style="height:180px;">
                <v-card-title>입고취소</v-card-title>
                <v-card-text>선택하신 항목을 입고취소 하시겠습니까?</v-card-text>
                <v-card-actions style="justify-content: end;">
                    <v-btn
                        color="white"
                        style="backgroundColor:green;"
                        text
                        @click="cancelWearing"
                    >확인</v-btn>
                    <v-btn
                        color="white"
                        style="backgroundColor:red;"
                        text
                        @click="dialogCancel = false"
                    >취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            top
        >
            {{ snackText }}
        </v-snackbar>
    </div>
</template>

<script>
import axios from 'axios'
import { emit } from 'process';
export default {
  name: "Wearing",
  components: {
    
  },
  data() {
    return {
        autoUpdate: true,
        headers: [            
            { value:'lotno', text:'LOT번호', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'is_inout', text:'IN/OUT', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'item_code', text:'품번', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'item_name', text:'품명', width:'10%', align:'start', sortable: true, class: 'cell-header', } ,
            { value:'specification', text:'규격', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'detail_info', text:'상세사양', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'sum_quan', text:'재고량', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'his_quan', text:'입/출고 수량', width:'8%', align:'start', sortable: true, class: 'cell-header', },
            { value:'bin_code', text:'위치정보', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'his_date', text:'입고날짜', width:'50', align:'start', sortable: true, class: 'cell-header', },
            { value:'blotid', text:'Batch LOTID', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'slotno', text:'공급사 LOT번호', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'validity_date', text:'유효기간', width:'50', align:'start', sortable: true, class: 'cell-header', },
            { value:'scomp_code', text:'공급사 코드', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'scomp_name', text:'공급 업체명', width:'200', align:'start', sortable: true, class: 'cell-header', },
            ],
        i_code:'',
        lot_code:'',
        datas:[],
        dataCounts:0,
        perPage:100,
        page:1,
        footerProps: { 'items-per-page-options': [100, -1] },
        options:{},
        params:{},

        lotno:null,

        sortBy: ['item_code','item_name'],
        sortDesc: [true,false],

        overlay: false,
        dialog_wearing:false,
        dialogCancel:false,

        search_item:null,
        value:'TP',
        searchInput:'TP',
        item_code:'TP',

        orderBy:null,
        order_desc:null,

        searchAdd:null,
        searchItemList:[],
 
        searchBinAdd:null,
        addBinCodes:[],

        add_item_uid:null,
        add_item_code:null,
        add_item_name:null,
        add_specification:null,
        add_detail_info:null,
        add_quan:null,
        add_bin_code:null,

        cards: [],
        selected:[],
        selectedCard:null,
        selectedItem:null,
        
        snack:false,
        snackColor:'',
        snackText:'',

        wearing_date_menu:false,
        date_range:'',
        start_date:'',
        end_date:'',

        inputStart:false,
        inputEnd:false,

        item_code_b:'',
        today:'',
        cancelDisabled:true,

        blotid:'',
        slotno:'',
        scomp_code:'',
        scomp_name:'',
        validity_date:null
    };
  },

  mounted() {
    //this.init(); 

    this.$nextTick(() => {
    });

    //this.set_input_date();
 },

  watch: {
        
      selected() {
        if(this.selected.length>0) {
            this.cancelDisabled = false
        } else {
            this.cancelDisabled = true
        }
      },
      getContextPath(){
            var hostIndex = location.href.indexOf( location.host ) + location.host.length;
            var contextPath = location.href.substring( hostIndex, location.href.indexOf('/', hostIndex + 1) );
            return contextPath;
      },
      searchAdd(val) {
        if(val == null || val.length < 1) return;
            var sresult='';
            if(val!='') {
                const str = val;
                const result = str.split("/");
                sresult = result[0]; 
            }
            else{
                sresult = '';
            }

        if(val == null || val.length < 1) return;

            var url = this.$vBACKEND_URL + '/item.do?method=readItem'; 
            var pObj = {
                uid_company:this.$store.getters.getUidCompany,
                search_item:sresult    
            }
            var params = '';
            for(var key in pObj) {
                params += '&' + key + '=' + pObj[key]
            }

            url += params;

            fetch(url)
                .then(res => res.clone().json())
                .then(res => {
                    this.searchItemList = res.result
                    this.item_uids = res.result[0].unique_id
                    this.clickAddItemCode(res.result[0])
                })
                .catch(err => {
                    console.log(err)
                })
      },
      searchBinAdd(val) {
          if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            bin_code:val
        }
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.addBinCodes = res.result
            })
            .catch(err => {
                console.log(err)
            })
      },

      wearing_date_menu() {
          if(this.wearing_date_menu) {
              this.inputStart = false;
              this.inputEnd = false;
          }
      },
      
     options(val) {
        var curPage = val.page;
        var itemsPerPage = val.itemsPerPage;
        if(itemsPerPage!=null && itemsPerPage==-1) {
            itemsPerPage = 100000000;
        }
        this.page = curPage;
        this.perPage = itemsPerPage;
        console.log(val.sortBy[0])
        if(val.sortBy[0]){
            if(val.sortBy[0]=="blotid"){
                this.orderBy = "&orderBy=history." + val.sortBy[0];
            }else if(val.sortBy[0]=="slotno" || val.sortBy[0]=="validity_date" || val.sortBy[0]=="scomp_code" || val.sortBy[0]=="scomp_name"){   
                this.orderBy = "&orderBy=location." + val.sortBy[0];                
            }else{    
                this.orderBy = "&orderBy=" + val.sortBy[0]; 
            }
            this.order_Desc = "&isAsc=" +  val.sortDesc[0];
        }
        else{
            this.orderBy=null;
            this.order_Desc=null;
        }       
       
        this.getDatas();
     }
  },
  methods: {
    toggleFullScreen() {
        const element = document.documentElement;

        if (!document.fullscreenElement) {
            element.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    },
    init() {
        this.getDatas()
    },
    onInput(item){
    },


    sortFunction(){
    },

    click_ColHeader(val){
    },

    closeList(){
    },

    searchAdd_void() {
        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            search_item:''
        }
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.searchItemList = res.result
            })
            .catch(err => {
                console.log(err)
            })
    },

    searchBinAdd_void() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            bin_code:''
        }
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;
        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.addBinCodes = res.result
           })
            .catch(err => {
                console.log(err)
            })
    },

   
    getDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
        var start = (this.page-1) * this.perPage;
        var limit = this.perPage;
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          start:start,
          limit:limit,
          gubun:'IN/OUT',
          apiType:'ALL'
        }


        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }

        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        if(this.orderBy != null && this.orderBy != 'undifined'){
            url += this.orderBy;
            url += this.order_Desc;
        }
        else{
            url += this.orderBy="&orderBy=history.lotno";
            url += this.order_Desc="&isAsc=false";            
        }

        this.item_code_b = url;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.datas;
              const count = res.count;
              this.datas = datas;
              this.dataCounts = count;
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialog = false;
          })
    },

    onClickCol(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }
    },

    search_1() {
      const query = this.$refs.add_bin_code.internalValue;
      if (query) {
        this.$refs.autocomplete.search = query;
      }
    },

    onClickRow(record, row) {
        this.resetAddForm();
        this.dialog_wearing=false;

        setTimeout(() => {
          this.searchAdd = row.item.item_code;
        }, 500)

        this.searchAdd =row.item.item_code;

        if(this.$refs.autoinput != null){
            if(this.$refs.autoinput.items[0] !=null){
                this.add_item_uid = this.$refs.autoinput.items[0].unique_id;
            }
            else{      
                this.add_item_uid = this.$refs.autoinput._props.value.unique_id;
            }
        }

        this.lotno = row.item.lotno;
        this.uid_company = this.$store.getters.getUidCompany;
        this.add_item_code = row.item.item_code;
        this.add_item_name = row.item.item_name;
        this.add_specification = row.item.specification;
        this.add_detail_info = row.item.detail_info        
        this.add_quan = 1;
        this.blotid = row.item.blotid;
        this.slotno = row.item.slotno;
        this.scomp_code = row.item.scomp_code;
        this.scomp_name = row.item.scomp_name;
        this.validity_date = row.item.validity_date;

        setTimeout(() => {
          this.searchAdd = row.item.item_code;
        }, 500)

    },

    disabledSelectAll(){
        const selectedItems = this.$refs.dataTable.selection;

        for(var key in selectedItems) {
            selectedItems[key] = false;
            key = null;
        }
        this.selected = [];
    },

    onTextChangne(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        }else {
            this.params[key]="";
            this.params[key] = val;
        }

    },

    onTextChangne2(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        }else {
            this.params[key]="";
            this.params[key] = val;
        }
    },

    findIndex (target) {
      return [].findIndex.call(this.elements, e => e === target)
    },
    moveFocus (index) {
      if (this.elements[index]) {
        this.elements[index].focus()
      }
    },
    moveNext (event) {
      const index = this.findIndex(event.target)
      this.moveFocus(index + 1)
    },
    movePrev (event) {
      const index = this.findIndex(event.target)
      this.moveFocus(index - 1)
    },
    next(e) {
      e.target?.nextSibling?.focus();
    },

    wearingExcelUpload() {
        let input = document.createElement('input')

        input.id = 'excel'
        input.type = 'file'
        input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        input.multiple = true

        input.click();

        var url = this.$vBACKEND_URL + '/template.do?method=execWearingExcel'
        var me = this

        // Event
        input.onchange = function() {
            me.overlay = true
            const formData = new FormData()
            formData.append('file', this.files[0])
            formData.append('uid_company', me.$store.getters.getUidCompany)
            formData.append('user_uid', me.$store.getters.getUserUid)
            formData.append('user_id', me.$store.getters.getUserId)
            formData.append('user_name', me.$store.getters.getUserName)

            axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                        me.snack = true;
                        me.snackColor = '#60C5F1';
                        me.snackText = '성공'

                        me.getDatas();
                    }).catch(err => {
                        console.log(err)
                        me.snack = true;
                        me.snackColor = "red";
                        me.snackText = '실패'
                    }).finally(() => {
                        me.overlay = false
                    })
        }
    },

    wearingExcelDownload() {
        this.overlay = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          gubun:'IN/OUT',
          apiType:'ALL',
          command:'EXCEL'
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        if(this.orderBy != null && this.orderBy != 'undifined'){
            url += this.orderBy;
            url += this.order_Desc;
        }
        else{
            url += this.orderBy="&orderBy=history.lotno";
            url += this.order_Desc="&isAsc=false";            
        }

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;
            if(excelPath!=null) {
                 this.snack = true;
                 this.snackColor = '#60C5F1';
                 this.snackText = '성공'
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath
            } else {
                 this.snack = true;
                 this.snackColor = 'FFFBE5';
                 this.snackText = '조회된 데이터가 없습니다.'
            }
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    },

    wearingExcelTemplate() {
        this.overlay = true
        var url = this.$vBACKEND_URL + '/template.do?method=wearingExcelTemplateDown';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
        }
        var params = '';
        for(var key in obj) {
        params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;

            if(excelPath!=null) {
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath
            } 
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    },

    clickCardBin(card) {
        this.searchBinAddFn(card.bin_code)
    },

    searchBinAddFn(val) {
        if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            bin_code:val
        }
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.addBinCodes = res.result
                this.add_bin_code = this.addBinCodes[0].bin_code
            })
            .catch(err => {
                console.log(err)
            })
    },

    clickAddItemCode(o) {
        if(o!=null) {
            this.selectedItem = o;
            this.add_item_uid = o.unique_id
            this.add_item_code = o.item_code
            this.add_item_name = o.item_name
            this.add_specification = o.specification
            this.add_detail_info = o.detail_info

            this.getAddCard(o);
        }
    },

    load_container(){
    },

    clickAddBinCode(o) {
        if(o!=null) {
            this.add_bin_code = o.bin_code
        }
    },

    getAddCard(o) {
        if(o==null || o.length<1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationByUidItem';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            uid_item:this.add_item_uid, 
        }

        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' +  obj[key] 
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const cards = res.result;
            this.cards = cards;
        })
        .catch(err => {
            console.log(err)
        })
    },

    execUnitWearing() {
        if(this.add_item_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '품번을 확인해주세요'
            return;
        }
        if(this.add_bin_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '위치정보를 확인해주세요'
            return;
        }
        if(this.add_quan==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '수량을 확인해주세요'
            return;
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execWearingBinCodeA';
        var obj = {
            set_lotno:this.lotno,
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            item_uids:this.add_item_uid,
            item_quans:this.add_quan,
            bin_code:this.add_bin_code,
            blotid:this.blotid,
            slotno:this.slotno,
            scomp_code:this.scomp_code,
            scomp_name:this.scomp_name,
            validity_date:this.validity_date
        }
        obj.item_uids = this.item_uids;

        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                let result = res.result
                if(result) {
                    this.snack = true;
                    this.snackColor = '#60C5F1';
                    this.snackText = '입고 성공'
                } else {
                    this.snack = true;
                    this.snackColor = "red";
                    this.snackText = '위치정보를 확인해주세요'
                }

                this.getDatas()
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.resetAddForm())

            this.item_code =this.add_item_code;
            this.i_code = this.add_item_code;
            this.$emit('i_code_update');
            this.onTextChangne('item_code', this.add_item_code)
            this.$refs.add_lotno.focus()
    },

    resetAddForm() {
        this.lotno = null;
        this.add_item_uid = null;
        this.search_item = null;
        this.add_item_code = null;
        this.add_item_name = null;
        this.add_specification = null;
        this.add_detail_info = null;
        this.add_quan = null;
        this.add_bin_code = null;
        this.cards = null;
        this.selectedCard = null;
        this.selectedItem = null;
        this.cards = [];
        this.selected = [];
        this.i_code = null;
        this.lot_code = null;
        this.blotid = null;
        this.slotno = null;
        this.scomp_code = null;
        this.scomp_name = null;
        this.validity_date = null;
    },

    set_input_date(){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        this.today = `${year}-${month}-${day}`;    

        this.$refs.start_date = this.today;
        this.$refs.end_date = this.today;

        const initialDate = new Date('2023-05-12');

        this.start_date = this.today;
        this.end_date = this.today;
        this.input_start();
        this.input_end();
    },

    input_start() {
        if(this.inputEnd) {
            this.date_range = this.start_date + " ~ " + this.end_date;
            this.wearing_date_menu = false;
            this.setDateRangeParam();
        }
        this.inputStart = true;
    },

    input_end() {
        if(this.inputStart) {
            this.date_range = this.start_date + " ~ " + this.end_date;
            this.wearing_date_menu = false;
            this.setDateRangeParam();
        }
        this.inputEnd = true;
    },

    setDateRangeParam() {
        if(this.start_date!=null && this.start_date.length>0 &&
            this.end_date!=null && this.end_date.length>0) {
            this.params['his_s_date'] = this.start_date;
            this.params['his_e_date'] = this.end_date;
        }
    },

    refreshDateRange() {
        this.start_date = '';
        this.end_date = '';
        this.date_range = '';
        this.params['his_s_date'] = "1997-01-01";
        this.params['his_e_date'] = Date().formData("yyyy-mm-dd");
    },

    cancelWearing() {
        this.overlay = true

        var uids = [];
        for(var i=0; i<this.selected.length; i++) {
            const select = this.selected[i]
            const uid = select.id;
            uids.push(uid)
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=cancelInOut'
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
        }
        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        params += '&' + 'unique_id_list=' + uids
        url += params

        fetch(url)
        .then(res => {
            this.snack = true;
            this.snackColor = 'green';
            this.snackText = '성공'

            this.getDatas()
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
        })
        .finally(() => {
            this.dialogCancel = false
            this.overlay = false
            this.cancelDisabled = true
        })

    },
  }
};
</script>

<style>
.v-data-table__wrapper {
    height:72vh !important;
}
.v-card {
    white-space: nowrap;
  }
  .cell-header, .lotno-header, .item_code-header, .citem_name-header {
    border-right: 1px solid rgb(238, 235, 235); 
    background: linear-gradient(to right, #ff6b6b, #556270); 
  }  

 .v-data-table-header {
    background: linear-gradient(to right, #ff6b6b, #556270); 
  color: white; 
  }
  .custom-text-field {
    height: 50px;
  }
  tr.v-data-table__selected {
    color: rgb(251, 251, 248);
    background: #7d92f5 !important;
}  
</style>